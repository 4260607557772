<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <div>
          <b-row>
            <b-col
              md="6"
              class="flex justify-content-start"
            />
            <b-col
              md="3"
            >
              <div class="d-flex justify-content-end">
                <b-button
                  variant="success"
                  block
                  @click="openall()"
                >
                  เปิดทั้งหมด
                </b-button>
              </div>
            </b-col>
            <b-col
              md="3"
            >
              <div class="d-flex justify-content-end">
                <!-- <b-form-select
                  v-model="search_type"
                  class="d-inline-block mr-1"
                  :options="search_type_option"
                />

                <b-form-input
                  v-model="search_val"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                /> -->
                <b-button
                  variant="primary"
                  block
                  @click="Submit()"
                >
                  บันทึก
                </b-button>
              </div>
            </b-col>
          </b-row>
          <!-- <div class="row">

            <div class="col">

            </div>
            <div class="col">

            </div>
            <div class="col"></div>
          </div>
          <div class="third1" /> -->
        </div>
      </b-card-body>
      <b-table
        small
        striped
        hover
        responsive
        class="position-relative items-center"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>

        <template #cell(status)="data">
          <div
            class="text-nowrap"
          >
            <b-form-checkbox
              v-model="data.item.status"
              :checked="data.item.status"
              class="custom-control-info"
              name="check-button"
              switch
              :value="1"
              :unchecked-value="0"
            >
              <span class="switch-icon-left">
                เปิด
              </span>
              <span class="switch-icon-right">
                ปิด
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(ag_status)="data">
          <div
            v-if="data.item.TypeID === 29"
            class="text-nowrap"
          >
            <b-form-checkbox
              v-model="data.item.ag_status"
              :checked="data.item.ag_status"
              class="custom-control-warning"
              name="check-button"
              switch
              value="1"
              unchecked-value="0"
            >
              <span class="switch-icon-left">
                เปิด
              </span>
              <span class="switch-icon-right">
                ปิด
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #row-details="row">
          <b-card>
            <b-table
              responsive="sm"
              :items="row.item.Subhead"
              :fields="SubFields"
            >
              <template #cell(index)="data">
                {{ perPage * (currentPage - 1) + (data.index + 1) }}
              </template>
              <template #cell(status)="data">
                <div class="text-nowrap">
                  <b-form-checkbox
                    v-model="data.item.status"
                    :checked="data.item.status"
                    class="custom-control-info"
                    name="check-button"
                    switch
                    value="1"
                    unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      เปิด
                    </span>
                    <span class="switch-icon-right">
                      ปิด
                    </span>
                  </b-form-checkbox>
                </div>
              </template>

            </b-table>
          </b-card>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0" />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  // BInputGroupPrepend,
  // BModal,
  BCard, BTable,
  // BAvatar,
  // BFormGroup, BFormSelect, BPagination,
  // BInputGroup,
  // BFormInput,
  BButton, BCardBody, VBToggle, BOverlay, BIconController, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    // vSelect,
    // BInputGroupPrepend,
    // BModal,
    BCard,
    BTable,
    // BAvatar,
    // BFormGroup,
    // BFormSelect,
    // BPagination,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      agentlottodata: {},
      UserData: JSON.parse(localStorage.getItem('userData')),
      search_val: null,
      search_type: 4,
      show: true,
      depositdata: [],
      perPage: 15,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      banklist: [],
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'name', label: 'หวย' },
        { key: 'status', label: 'สถานะ' },
        { key: 'ag_status', label: 'ออกผลเอง' },
      ],
      SubFields: [
        { key: 'index', label: 'no.' },
        { key: 'name', label: 'หวย' },
        { key: 'status', label: 'สถานะ' },
        { key: 'ag_status', label: 'ออกผลเอง' },
        { key: 'u3', label: 'หวย 3 ตัวบน' },
        { key: 'd3', label: 'หวย 3 ตัวล่าง' },
        { key: 't3', label: 'หวย 3 ตัวโต๊ด' },
        { key: 'u2', label: 'หวย 2 ตัวบน' },
        { key: 'd2', label: 'หวย 2 ตัวล่าง' },
        { key: 't2', label: 'หวย 2 ตัวโต๊ด(บน)' },
        { key: 'u1', label: 'วิ่งบน' },
        { key: 'd1', label: 'วิ่งล่าง' },
      ],
      /* eslint-disable global-require */
      items: [],
      DefaultItem: [],
      search_type_option: [
        { value: 4, text: 'ยูสเซอร์เนม' },
        { value: 3, text: 'เบอร์โทร' },
        { value: 1, text: 'IP ADDRESS' },
        { value: 2, text: 'BROWSER ID' },
      ],
      addNewDataSidebar: false,
      Interval: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    // visibleFields() {
    //   return this.fields.filter(field => field.visible)
    // },
  },
  async mounted() {
    this.totalRows = this.items.length
    await this.GetlottoAgent()
    this.GetData()
  },
  methods: {
    openall() {
      // eslint-disable-next-line no-restricted-syntax
      for (const items of this.items) {
        items.status = 1
      }
    },
    CheckDiff() {
      const DiffArr = []
      const DiffSubArr = []
      // eslint-disable-next-line
      for (const K in this.items) {
        // eslint-disable-next-line
        for (const I in this.items[K]) {
          if (I === 'Subhead') {
            // eslint-disable-next-line
            for (const KeySub in this.items[K][I]) {
              // eslint-disable-next-line
              for (const KeyInSub in this.items[K][I][KeySub]) {
                if (this.items[K][I][KeySub][KeyInSub] !== this.DefaultItem[K][I][KeySub][KeyInSub]) {
                  DiffSubArr.push(this.items[K][I][KeySub])
                  break
                }
              }
            }
          // eslint-disable-next-line eqeqeq
          } else if (this.items[K][I] != this.DefaultItem[K][I] && I !== '_showDetails') {
            DiffArr.push(this.items[K])
            break
          }
        }
      }
      return { DiffArr, DiffSubArr }
    },
    async Check() {
      const senddata = {
        id: this.UserData.userID,
        lotto1: null,
        lotto2: null,
        lotto3: null,
        lotto4: null,
        lotto5: null,
        lotto6: null,
        lotto7: null,
        lotto8: null,
        lotto9: null,
        lotto10: null,
        lotto11: null,
        lotto12: null,
        lotto13: null,
        lotto14: null,
        lotto15: null,
        lotto16: null,
        lotto17: null,
        lotto18: null,
        lotto19: null,
        lotto20: null,
        lotto21: null,
        lotto22: null,
        lotto23: null,
        lotto24: null,
        lotto25: null,
        lotto26: null,
        lotto27: null,
        lotto28: null,
        lotto29: null,
        lotto29_manual_result: null,
      }
      console.log(senddata)
      // eslint-disable-next-line no-restricted-syntax
      for await (const items of this.items) {
        if (items.TypeID === 1) {
          if (items.status === 1) {
            senddata.lotto1 = 1
          } else {
            senddata.lotto1 = 0
          }
        }
        if (items.TypeID === 2) {
          if (items.status === 1) {
            senddata.lotto2 = 1
          } else {
            senddata.lotto2 = 0
          }
        }
        if (items.TypeID === 3) {
          if (items.status === 1) {
            senddata.lotto3 = 1
          } else {
            senddata.lotto3 = 0
          }
        }
        if (items.TypeID === 4) {
          if (items.status === 1) {
            senddata.lotto4 = 1
          } else {
            senddata.lotto4 = 0
          }
        }
        if (items.TypeID === 5) {
          if (items.status === 1) {
            senddata.lotto5 = 1
          } else {
            senddata.lotto5 = 0
          }
        }
        if (items.TypeID === 6) {
          if (items.status === 1) {
            senddata.lotto6 = 1
          } else {
            senddata.lotto6 = 0
          }
        }
        if (items.TypeID === 7) {
          if (items.status === 1) {
            senddata.lotto7 = 1
          } else {
            senddata.lotto7 = 0
          }
        }
        if (items.TypeID === 8) {
          if (items.status === 1) {
            senddata.lotto8 = 1
          } else {
            senddata.lotto8 = 0
          }
        }
        if (items.TypeID === 9) {
          if (items.status === 1) {
            senddata.lotto9 = 1
          } else {
            senddata.lotto9 = 0
          }
        }
        if (items.TypeID === 10) {
          if (items.status === 1) {
            senddata.lotto10 = 1
          } else {
            senddata.lotto10 = 0
          }
        }
        if (items.TypeID === 11) {
          if (items.status === 1) {
            senddata.lotto11 = 1
          } else {
            senddata.lotto11 = 0
          }
        }
        if (items.TypeID === 12) {
          if (items.status === 1) {
            senddata.lotto12 = 1
          } else {
            senddata.lotto12 = 0
          }
        }
        if (items.TypeID === 13) {
          if (items.status === 1) {
            senddata.lotto13 = 1
          } else {
            senddata.lotto13 = 0
          }
        }
        if (items.TypeID === 14) {
          if (items.status === 1) {
            senddata.lotto14 = 1
          } else {
            senddata.lotto14 = 0
          }
        }
        if (items.TypeID === 15) {
          if (items.status === 1) {
            senddata.lotto15 = 1
          } else {
            senddata.lotto15 = 0
          }
        }
        if (items.TypeID === 16) {
          if (items.status === 1) {
            senddata.lotto16 = 1
          } else {
            senddata.lotto16 = 0
          }
        }
        if (items.TypeID === 17) {
          if (items.status === 1) {
            senddata.lotto17 = 1
          } else {
            senddata.lotto17 = 0
          }
        }
        if (items.TypeID === 18) {
          if (items.status === 1) {
            senddata.lotto18 = 1
          } else {
            senddata.lotto18 = 0
          }
        }
        if (items.TypeID === 19) {
          if (items.status === 1) {
            senddata.lotto19 = 1
          } else {
            senddata.lotto19 = 0
          }
        }
        if (items.TypeID === 20) {
          if (items.status === 1) {
            senddata.lotto20 = 1
          } else {
            senddata.lotto20 = 0
          }
        }
        if (items.TypeID === 21) {
          if (items.status === 1) {
            senddata.lotto21 = 1
          } else {
            senddata.lotto21 = 0
          }
        }
        if (items.TypeID === 22) {
          if (items.status === 1) {
            senddata.lotto22 = 1
          } else {
            senddata.lotto22 = 0
          }
        }
        if (items.TypeID === 23) {
          if (items.status === 1) {
            senddata.lotto23 = 1
          } else {
            senddata.lotto23 = 0
          }
        }
        if (items.TypeID === 24) {
          if (items.status === 1) {
            senddata.lotto24 = 1
          } else {
            senddata.lotto24 = 0
          }
        }
        if (items.TypeID === 25) {
          if (items.status === 1) {
            senddata.lotto25 = 1
          } else {
            senddata.lotto25 = 0
          }
        }
        if (items.TypeID === 26) {
          if (items.status === 1) {
            senddata.lotto26 = 1
          } else {
            senddata.lotto26 = 0
          }
        }
        if (items.TypeID === 27) {
          if (items.status === 1) {
            senddata.lotto27 = 1
          } else {
            senddata.lotto27 = 0
          }
        }
        if (items.TypeID === 28) {
          if (items.status === 1) {
            senddata.lotto28 = 1
          } else {
            senddata.lotto28 = 0
          }
        }
        if (items.TypeID === 29) {
          if (items.status === 1) {
            senddata.lotto29 = 1
          } else {
            senddata.lotto29 = 0
          }
          if (items.ag_status === 1 || items.ag_status === '1') {
            senddata.lotto29_manual_result = 1
          } else {
            senddata.lotto29_manual_result = 0
          }
        }
      }
      return senddata
    },
    async Submit() {
      this.show = true
      const data = await this.Check()
      this.$http
        .post('agent/lottoagent/update', data)
        .then(() => {
          this.show = false
          this.GetlottoAgent()
          this.GetData()
          this.Success()
        })
        .catch(error => console.log(error))
    },
    async GetlottoAgent() {
      await this.$http
        .get(`agent/lottoagent/${this.UserData.userID}`)
        .then(response => {
          this.agentlottodata = response.data
        })
        .catch(error => console.log(error))
    },
    async GetData() {
      await this.$http
        .get('lottotype')
        .then(response => {
          this.show = false
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    async onFiltered(filteredItems) {
      // eslint-disable-next-line no-restricted-syntax
      for await (const item of filteredItems) {
        if (item.TypeID === 1) {
          if (this.agentlottodata.lotto1 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 2) {
          if (this.agentlottodata.lotto2 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 3) {
          if (this.agentlottodata.lotto3 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 4) {
          if (this.agentlottodata.lotto4 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 5) {
          if (this.agentlottodata.lotto5 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 6) {
          if (this.agentlottodata.lotto6 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 7) {
          if (this.agentlottodata.lotto7 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 8) {
          if (this.agentlottodata.lotto8 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 9) {
          if (this.agentlottodata.lotto9 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 10) {
          if (this.agentlottodata.lotto10 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 11) {
          if (this.agentlottodata.lotto11 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 12) {
          if (this.agentlottodata.lotto12 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 13) {
          if (this.agentlottodata.lotto13 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 14) {
          if (this.agentlottodata.lotto14 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 15) {
          if (this.agentlottodata.lotto15 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 16) {
          if (this.agentlottodata.lotto16 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 17) {
          if (this.agentlottodata.lotto17 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 18) {
          if (this.agentlottodata.lotto18 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 19) {
          if (this.agentlottodata.lotto19 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 20) {
          if (this.agentlottodata.lotto20 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 21) {
          if (this.agentlottodata.lotto21 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 22) {
          if (this.agentlottodata.lotto22 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 23) {
          if (this.agentlottodata.lotto23 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 24) {
          if (this.agentlottodata.lotto24 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 25) {
          if (this.agentlottodata.lotto25 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 26) {
          if (this.agentlottodata.lotto26 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 27) {
          if (this.agentlottodata.lotto27 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 28) {
          if (this.agentlottodata.lotto28 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
        }
        if (item.TypeID === 29) {
          if (this.agentlottodata.lotto29 === 1) {
            item.status = 1
          } else {
            item.status = 0
          }
          if (this.agentlottodata.lotto29_manual_result === 1) {
            item.ag_status = 1
          } else {
            item.ag_status = 0
          }
        }
      }
      this.items = filteredItems
      const St = JSON.stringify(filteredItems)
      this.DefaultItem = JSON.parse(St)
      this.totalRows = this.items.length
      // this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">บันทึกสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
